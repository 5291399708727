<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <el-input placeholder="客户名称/联系人/电话" style="width:300px" v-model.trim="searchValue" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" @input="curr=1;getList()"></el-input>
            <!-- <el-select v-model="select.is_stop" placeholder="状态" clearable style="width:140px" @change="curr=1;getList()">
                <el-option label="启用" value="1"></el-option>
                <el-option label="禁用" value="2"></el-option>
            </el-select> -->
            <!-- <el-input placeholder="搜索" v-model.trim="searchValue" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" style="width:150px;margin-right:auto;" @input="curr=1;getList()"></el-input> -->
            <!-- <el-button type="primary" block size="small" style="margin-left:auto" @click="edit()">新建</el-button> -->
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="user.name" label="客户名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="user.contacts" label="联系人" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="user.mobile" label="电话" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="brand_name" label="产品" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="图片" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image v-if="scope.row.img_arr&&scope.row.img_arr.length>0" style="width: 50px; height: 50px" :src="getImgUrl(scope.row.img_arr[0])" :preview-src-list="getImgUrl(scope.row.img_arr,'arr')" fit="cover"></el-image>
                    <p v-else>暂无</p>
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="申请时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="状态" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <!-- <p class="ellipsis">{{scope.row.status==1?'已通过':scope.row.status==2?'已拒绝':'未审核'}}</p> -->
                    <el-tag size="small" type="success" v-if="scope.row.status==1">已通过</el-tag>
                    <el-tag size="small" type="danger" v-else-if="scope.row.status==2">已拒绝</el-tag>
                    <el-tag size="small" type="primary" v-else>待审核</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="">
                        <!-- <el-link type="success" @click="edit(scope.row)" :underline="false">通过</el-link>
                        <el-link type="danger" @click="handleDel(scope.row.id)" :underline="false">拒绝</el-link> -->

                        <el-button plain type="success" size="mini" @click="handleAudit(scope.row,1)">通过</el-button>
                        <el-button plain type="danger" size="mini" @click="handleAudit(scope.row,2)">拒绝</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            select: {},
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数

            formData: {},
            dialogTitle: '',
            dialogIsShow: false,
            siteList: [],
        }
    },
    mounted: function() {
        this.getList();
    },
    methods: {
        handleAudit(row, stateId) {
            if (stateId == 2) { //拒绝时
                this.$prompt(' ', '审批拒绝', {
                    inputPlaceholder: '请填写拒绝原因',
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    closeOnClickModal: false,
                    inputPattern: /\S/,
                    inputErrorMessage: '不得为空'
                }).then(({
                    value
                }) => {
                    this.http.post('/admin.carCareAudit/status', {
                        reason: value,
                        id: row.id,
                        status: stateId
                    }).then(() => {
                        this.getList()
                        this.$message({
                            type: 'success',
                            message: '已拒绝!'
                        });
                    })
                }).catch(() => {});
            } else {
                this.$confirm('确认审核通过吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.http.post('/admin.carCareAudit/status', {
                        id: row.id,
                        status: stateId
                    }).then(() => {
                        this.getList()
                        this.$message({
                            type: 'success',
                            message: '已通过!'
                        });
                    })
                })
            }
        },
        getList: function() {
            this.http.post('/admin.carCareAudit/index', {
                curr: this.curr,
                row: this.row,
                search: this.searchValue
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
                this.$nextTick(() => {
                    this.$refs.table.doLayout()
                })
            })
        },
        // 图片转换
        getImgUrl(item, type) {
            var url;
            var urlArr=[]
            if (type == 'arr') {
                item.forEach((i) => {
                    urlArr.push(this.domain + i);
                })
                return urlArr;
            } else {
                url = this.domain + item;
                return url;
            }
        },
        edit: function(row = {}) {
            if (typeof row.id == 'undefined') {
                this.dialogTitle = "新建账号";
            } else {
                this.dialogTitle = "编辑账号";
                this.formData = {};
                row.password = '';
                this.formData = JSON.parse(JSON.stringify(row));
            }
            this.dialogIsShow = true
        },
        handleDel(id) {
            this.$confirm('此操作将永久删除该条信息, 是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.http.post('/user/delete', {
                    id: id
                }).then(() => {
                    this.getList();
                    this.$message({
                        message: '成功删除',
                        type: 'success'
                    })
                })
            })
        },
        //监听表单关闭事件
        dialogClose() {
            this.dialogIsShow = false;
            this.formData = {};
            this.$refs.form.resetFields();
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.formData.password = this.formData.password ? this.$md5(this.formData.password) : '';
                    this.http.post('/user/edit', this.formData).then(() => {
                        this.dialogClose();
                        this.getList();
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        })
                    })
                }
            })
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
